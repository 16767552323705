<template>
	<v-app>
		<v-row>
			<v-col>
				<v-data-table
				dense
				:headers="headers"
				:items="items"
				:search="search"
        :single-expand="true"
        :expanded.sync="expanded"
				class="elevation-1"
				:loading="loading"
        show-expand
				:items-per-page="pages"
				loading-text="Cargando..."
				>
         <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Insumos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
              </v-btn>
              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field 
                          v-model="editedItem.quantity" 
                          label="Cantidad"
                          :rules="rules.in_req"
                          color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-checkbox
                            v-model="editedItem.invProduct"
                            label="Distribuidora"
                            color="primary"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                            v-model="description" 
                            label="Descripción"
                            :rules="rules.in_req"
                            color="purple darken-2"
                            v-show="!editedItem.invProduct"
                          ></v-text-field>
                          <v-autocomplete
                          v-model="product" 
                          :items="itemsProducts"
                          item-text="name"
                          item-value="id"
                          label="Productos"
                          v-show="editedItem.invProduct"
                          clearable
                          return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-autocomplete
                          v-model="editedItem.taker" 
                          :items="itemsEmployees"
                          item-text="name"
                          item-value="id"
                          label="Profesional"
                          clearable
                          return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                          v-model="total" 
                          label="Total"
                          color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>                     
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>3
              </v-dialog>  
              <v-dialog v-model="dialogPayment" max-width="100%" dense persistent>
                <v-card>
                  <v-card-title>
                  <span class="headline">Añadir Abono</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-autocomplete
                          v-model="editedItemPay.taker" 
                          :items="itemsEmployees"
                          item-text="name"
                          item-value="id"
                          label="Profesional"
                          clearable
                          :disabled="disable"
                          return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                          v-model="editedItemPay.total" 
                          label="Abono"
                          :rules="rules.in_req"
                          color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="savePayment">Guardar</v-btn>
                  </v-card-actions>
                </v-card>3
              </v-dialog>            
            </v-toolbar>
          </template>
          <template v-slot:item.exp_date="{ item }">
            {{ item.exp_date.split("T")[0] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="!item.cancelled">
              <v-icon small class="mr-2" @click="editPayment(item)">mdi-pencil</v-icon>
            </div>
          </template>
          <template v-slot:item.cancelled="{ item }">
            <div v-if="item.cancelled">
              <v-btn
                class="ma-2"
                color="success"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                class="ma-2"
                color="red"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-cancel
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                dense
                :headers="itemsHeaders"
                :items="item.payments_supplies"
                hide-default-footer
                class="elevation-1"

              >
              <template v-slot:item.cancel_payment_date="{ item }">
                {{ item.cancel_payment_date.split("T")[0] }}
              </template>
              <template v-slot:item.hour="{ item }">
                {{ item.cancel_payment_date.split("T")[1].split("-")[0].split(".")[0] }}
              </template>
            </v-data-table>
            </td>
          </template>
          <template v-slot:item.product="{ item }">
						<div v-if="item.product">
							{{item.product}}
						</div>
						<div else>
							{{item.inv_product_desc}}
						</div>
					</template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
	</v-app>
</template>

<script>

import { ApiPay } from "../billing/ApiPay";
import { ApiInv  } from "../inventory/ApiInv";
import { ApiExp } from "./ApiExp"

export default {
	name: "Supplies",
	data() {
    return{
      api: new ApiExp(),
      apiPay: new ApiPay(),
      apiInv: new ApiInv(),
      items:[],
      itemsEmployees: [],
      itemsProducts: [],
      products: [],
      expanded: [],
      loading: false,
      dialog:false,
      dialogPayment:false,
      search:"",
      pages:-1,
      editedIdex:-1,
      disable:false,
      product: {},
      description: "",
      total_product: 0,
      editedItem:{
          id:-1,
          taker:{id:-1},
          quantity:0,
          invProduct: false,
      },
      defaultItem:{
          id:-1,
          taker:{id:-1},
          quantity:0,
          invProduct: false,
      },
      editedItemPay:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      defaultItemPay:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id_by_enterprise",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "exp_date",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "product",
        },
        {
          text: "Profesional",
          align: "start",
          sortable: true,
          value: "taker_desc",
        },
        {text:'Total',value:'total' ,sortable:true},
        {text:'Debe',value:'debe' ,sortable:true},
        {text:'Acciones',value:'actions',sortable:false},
      ],
      itemsHeaders: [
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "cancel_payment_date",
        },
        {
          text: "Hora",
          align: "center",
          sortable: true,
          value: "hour",
        },
        {text:'Total',value:'total' ,sortable:true},
      ],
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
    }
	},
  computed:{
    formTitle(){
      return (this.editedIdex === -1 ? "Nuevo": "Editar") + " Préstamo Insumo"
    },

    total: {
      get: function () {
        if (this.editedItem.invProduct === true && this.product){
          return this.product.sale_price * parseInt(this.editedItem.quantity)
        } else {
          return 0
        }
      },
      set: function (val) {
        this.total_product = val
      }
    },

    formIsValid () {
      return (
        (
          this.editedItemPay.taker ||
          this.editedItem.taker
        ) &&
        (
          this.editedItemPay.total >= 1 ||
          this.total >= 1 ||
          this.total_product >= 1
        )
      )
    },      
  },
   watch:{
      dialog(val){
          val || this.close()
      },
      dialogPayment(val){
          val || this.close()
      },
  },
  methods:{
    async iniciar(){
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}` 
        let r = await this.api.getSuppliesP(params)
        let rP = await this.apiInv.getProductosP(params);
        let rE = await this.apiPay.getEmpleados();
        this.items = r
        this.itemsEmployees = rE;
        this.itemsProducts = rP;
      } catch (error) {
          alert(error);
          // this.mensaje(error,"Error","error")
          this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    close(){
      this.dialog = false
      this.total = 0
      this.total_product = 0
      this.description = ""
      this.dialogPayment = false
      this.disable = false
      this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    async editPayment(item){
      this.editedIdex = this.items.indexOf(item)
      this.editedItemPay = Object.assign({},item)
      this.editedItemPay["total"] = 0
      this.dialogPayment = true
      this.disable = true
    },
    async save(){
      const cp = this.editedItem;
      cp.enterprise = localStorage.getItem("SucursalId")
      cp.taker = this.editedItem["taker"]["id"]
            
      if (this.editedItem["invProduct"] === true) {
        cp.inv_product = this.product["id"]
        cp.total = this.total
      } else {
        cp.product = this.description
        cp.total = this.total_product
      }
      
      try {
        this.loading = true
        let r = await this.api.saveSupplies(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }else{
          this.$swal("Guardado","Préstamo Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
    async savePayment(){

      const cp = {};
      cp["id"] = -1      
      cp["supply"] = this.editedItemPay["id"]      
      cp["total"] = this.editedItemPay["total"]

      try {
        this.loading = true
        let r = await this.api.saveAbonosSupplies(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }else{
          this.$swal("Guardado","Abono Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
  },
  created(){
    this.iniciar()
  }
};
</script>