import Api from "../Api"

export class ApiExp extends Api{
    constructor(){
        super()
    }

    async getPettyExpensesP(params, id=-1){
        return await super.getParams("gastos/gastos-menores", id, params)
    }

    async savePettyExpenses(obj){
        return await super.save("gastos/gastos-menores", obj)
    }

    async getOperationalExpensesP(params, id=-1){
        return await super.getParams("gastos/gastos-operacionales", id, params)
    }

    async saveOperationalExpenses(obj){
        return await super.save("gastos/gastos-operacionales", obj)
    }

    async getFixedExpensesP(params, id=-1){
        return await super.getParams("gastos/gastos-fijos", id, params)
    }

    async saveFixedExpenses(obj){
        return await super.save("gastos/gastos-fijos", obj)
    }

    async getOrigenPagoP(params, id=-1){
        return await super.getParams("gastos/origen-pago", id, params)
    }

    async saveOrigenPago(obj){
        return await super.save("gastos/origen-pago", obj)
    }

    async getValesP(params, id=-1){
        return await super.getParams("reportes/vales", id, params)
    }

    async saveVouchers(obj){
        return await super.save("reportes/vales", obj)
    }

    async getLoansP(params, id=-1){
        return await super.getParams("reportes/prestamos", id, params)
    }

    async saveLoans(obj){
        return await super.save("reportes/prestamos", obj)
    }

    async updateVale(obj){
        return await super.save("reportes/registrar-vale", obj)
    }

    async saveAbonos(obj){
        return await super.save("reportes/abonos", obj)
    }

    async getSuppliesP(params, id=-1){
        return await super.getParams("reportes/insumos", id, params)
    }

    async saveSupplies(obj){
        return await super.save("reportes/insumos", obj)
    }

    async saveAbonosSupplies(obj){
        return await super.save("reportes/abonos-insumos", obj)
    }
    
    async getFinesP(params, id=-1){
        return await super.getParams("reportes/multas", id, params)
    }

    async saveFines(obj){
        return await super.save("reportes/multas", obj)
    }

    // async delExpenses(id){
    //     return await super.delete("facturacion/clientes", id)
    // }
}