<template>
  <div class="pl-14">
    <v-card
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      absolute
      dark
      scroll-target="#scrolling-techniques-6"
      elevate-on-scroll
    >
      <v-card-title>
        <h1 class="font-weight-bold display-6 color-texto">Gastos {{sucursal}}</h1>
      </v-card-title>
    </v-card>
      <v-tabs 
        grow
        dark
      >
          <v-tab>Gastos Menores</v-tab>
          <v-tab>Gastos Operacionales</v-tab>
          <v-tab>Gastos Fijos</v-tab>
          <v-tab>Vales</v-tab>
          <v-tab>Prestamos</v-tab>
          <v-tab>Insumos</v-tab>
          <v-tab>Multas</v-tab>
          <v-tab>Fuentes Pago</v-tab>
          <v-tab-item><br><GastosMenores /></v-tab-item>
          <v-tab-item><br><GastosOperacionales /></v-tab-item>
          <v-tab-item><br><GastosFijos /></v-tab-item>
          <v-tab-item><br><Vouchers /></v-tab-item>
          <v-tab-item><br><Loans /></v-tab-item>
          <v-tab-item><br><Supplies /></v-tab-item>
          <v-tab-item><br><Fines /></v-tab-item>
          <v-tab-item><br><PayOrigins /></v-tab-item>
      </v-tabs>
    <v-sheet id="scrolling-techniques-6" class="overflow-y-auto" max-height="600">
      <v-container style="height: 60px;"></v-container>
    </v-sheet>
  </div>
</template>

<script>

import GastosMenores from "./PettyExp"
import GastosOperacionales from "./OperExp"
import GastosFijos from "./FixedExp"
import PayOrigins from "./PayOrigins"
import Vouchers from "./Vouchers"
import Loans from "./Loans"
import Supplies from "./Supplies"
import Fines from "./Fines"

export default {

  name: "Facturacion",
  components: {
    GastosMenores,
    GastosOperacionales,
    GastosFijos,
    PayOrigins,
    Vouchers,
    Loans,
    Supplies,
    Fines,
  },
  data() {
    return {
        opt: null,
        collapseOnScroll: true,
        sucursal: localStorage.getItem("Sucursal")
    };
  },
  methods: {
    chooseOpt(opt){
      this.opt = opt
    }
  }
};
</script>

<style scoped>
.color-texto {
    color: #356859 !important;
}
</style>
