<template>
	<v-app>
		<v-row>
			<v-col>
				<v-data-table
				dense
				:headers="headers"
				:items="items"
				:search="search"
				class="elevation-1"
				:loading="loading"
				:items-per-page="pages"
				loading-text="Cargando..."
				>
         <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Multas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
              </v-btn>
              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                        <v-row>
                          <v-col cols="5">
                              <v-autocomplete
                              v-model="editedItem.taker" 
                              :items="itemsEmployees"
                              item-text="name"
                              item-value="id"
                              label="Profesional"
                              clearable
                              :disabled="disable"
                              return-object
                              ></v-autocomplete>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field 
                            v-model="editedItem.description" 
                            label="Descripción"
                            :rules="rules.in_value"
                            :disabled="disable"  
                            color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field 
                            v-model="editedItem.total" 
                            label="Total"
                            :disabled="disable"  
                            color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-btn 
                              v-show="disable"
                              color="primary"
                              @click="save(true)"
                            >Cancelar Multa
                            </v-btn>
                          </v-col>
                        </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn 
                      color="pink accent-3" 
                      v-show="!disable"
                      :disabled="!formIsValid" 
                      text 
                      @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
            </v-toolbar>
          </template>
          <template v-slot:item.exp_date="{ item }">
            {{ item.exp_date.split("T")[0] }}
          </template>
          <template v-slot:item.hour="{ item }">
            {{ item.exp_date.split("T")[1].split("-")[0].split(".")[0] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="!item.cancelled">
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            </div>
          </template>
          <template v-slot:item.cancelled="{ item }">
            <div v-if="item.cancelled">
              <v-btn
                class="ma-2"
                color="success"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                class="ma-2"
                color="red"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-cancel
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.cancel_date="{ item }">
            <div v-if="item.cancel_date !== null">
              {{ item.cancel_date.split("T")[0] }} {{ item.cancel_date.split("T")[1].split("-")[0].split(".")[0] }}
            </div>
          </template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
	</v-app>
</template>

<script>

import { ApiPay } from "../billing/ApiPay";
import { ApiExp } from "./ApiExp"

export default {
	name: "Fines",
	data() {
    return{
      api: new ApiExp(),
      apiPay: new ApiPay(),
      items:[],
      itemsEmployees: [],
      loading: false,
      dialog:false,
      search:"",
      pages:-1,
      editedIdex:-1,
      disable:false,
      editedItem:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      defaultItem:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id_by_enterprise",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "exp_date",
        },
        {
          text: "Profesional",
          align: "center",
          sortable: true,
          value: "taker_desc",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Cancelado",
          align: "center",
          sortable: true,
          value: "cancelled",
        },
        {
          text: "Fecha cancelado",
          align: "center",
          sortable: true,
          value: "cancel_date",
        },
        {text:'Total',value:'total' ,sortable:true},
        {text:'Acciones',value:'actions',sortable:false},
      ],
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
    }
	},
  computed:{
    formTitle(){
      return (this.editedIdex === -1 ? "Nueva": "Editar") + " Multa"
    },
    formIsValid () {
      return (
        this.editedItem.taker &&
        this.editedItem.total >= 1
      )
    },      
  },
   watch:{
      dialog(val){
          val || this.close()
      },
  },
  methods:{
    async iniciar(){
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}` 
        let r = await this.api.getFinesP(params)
        let rE = await this.apiPay.getEmpleados();
        this.items = r
        this.itemsEmployees = rE;
      } catch (error) {
          alert(error);
          // this.mensaje(error,"Error","error")
          this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    close(){
      this.dialog = false
      this.disable = false
      this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    editItem(item){
      this.editedIdex = -1
      this.editedIdex = this.items.indexOf(item)
      this.editedItem = Object.assign({},item)
      this.dialog = true
      this.disable = true
    },
    // async cancelVoucher(){
    //   try {
    //     this.loading = true
    //     const cp = this.editedItem;
    //     cp["cancelled"] = true
    //     let r = await this.api.saveVouchers(cp)
    //     if (!r.ok){
    //       this.$swal("Error", Object.values(r)[0][0],"error")
    //     }else{
    //       this.$swal("Guardado","Vale Guardado Satisfactoriamente", "success")
    //       this.close()
    //       this.iniciar()
    //     }
    //   } catch (error) {
    //     alert(error)
    //     // this.mensaje(error,"Error","error")
    //   } finally{
    //     this.loading = false
    //   }
    // },
    async save(cancelled=false){
      const cp = this.editedItem;
      if (cancelled===true){
        cp.cancelled = cancelled
      }else{
        cp.enterprise = localStorage.getItem("SucursalId")
        cp.taker = this.editedItem["taker"]["id"]
      }
      console.log(cp)

      try {
        this.loading = true
        let r = await this.api.saveFines(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }else{
          this.$swal("Guardado","Multa Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
  },
  created(){
    this.iniciar()
  }
};
</script>