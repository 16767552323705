<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1"
        :loading="loading"
        :items-per-page="pages"
        loading-text="Cargando..."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Gastos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
              </v-btn>

              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                        <v-row>
                          <v-col cols="2">
                              <v-text-field 
                              v-model="editedItem.reference" 
                              label="Referencia"
                              :rules="rules.in_req"  
                              color="purple darken-2"
                              ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                              <v-text-field 
                              v-model="editedItem.description" 
                              label="Concepto"
                              :rules="rules.in_req"  
                              color="purple darken-2"
                              ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                              <v-autocomplete
                              v-model="editedItem.origin" 
                              :items="originItems"
                              item-text="name"
                              label="Origen"
                              clearable
                              return-object
                              ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field 
                            v-model="editedItem.observation" 
                            label="Observación"
                            :rules="rules.in_req"
                            color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field 
                            v-model="editedItem.total" 
                            label="Total"
                            :rules="rules.in_req"
                            color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.exp_date="{ item }">
            {{ item.exp_date.split("T")[0] }}
          </template>
          <template v-slot:item.hour="{ item }">
            {{ item.exp_date.split("T")[1].split("-")[0].split(".")[0] }}
          </template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { ApiExp } from "./ApiExp";
// import mensajesMixin from "../../../mixins/mensajesMixin"

export default {
  name: "GastosFijos",
//   mixins:[mensajesMixin],
  data() {
    return {
      items: [],
      originItems:[],
      api: new ApiExp(),
      loading: false,
      search: "",
      pages:-1,
      headers: [
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "reference",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "exp_date",
        },
        {
          text: "Hora",
          align: "center",
          sortable: true,
          value: "hour",
        },
        {
          text: "Concepto",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Observaciones",
          align: "start",
          sortable: true,
          value: "observation",
        },
        {
          text: "Origen",
          align: "start",
          sortable: true,
          value: "origin_desc",
        },
        {text:'Total',value:'total' ,sortable:true},
      ],
      dialog:false,
      editedIdex:-1,
      editedItem:{
          id:-1,
          description:"",
          total:0,
      },
      defaultItem:{
          id:-1,
          description:"",
          origin:{"id":-1},
          total:0,
      },
      categorias:[],
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
    };
  },
  computed:{
      formTitle(){
          return (this.editedIdex === -1 ? "Nuevo": "Editar") + " Gasto"
      },
      formIsValid () {
        return (
          this.editedItem.description &&
          this.editedItem.total
        )
      },      
  },
  watch:{
      dialog(val){
          val || this.close()
      }
  },
  methods: {
    async iniciar() {
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}`
        let r = await this.api.getFixedExpensesP(params);
        let rS = await this.api.getOrigenPagoP(params);
        this.items = r;
        this.originItems = rS;
      } catch (error) {
        alert(error);
        // this.mensaje(error,"Error","error")
        this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    close(){
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    },
    editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
    },
    async save(){
      const cp = this.editedItem;
      cp.enterprise = localStorage.getItem("SucursalId")
      cp.origin = this.editedItem["origin"]["id"]

      try {
        this.loading = true
        let r = await this.api.saveFixedExpenses(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }
        else{
          this.$swal("Guardado","Gasto Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
  },
  created() {
    this.iniciar();
  },
};
</script>