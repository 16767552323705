var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.pages,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Multas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","dark":""},on:{"click":_vm.iniciar}},[_c('v-icon',[_vm._v("cached")])],1),_c('v-dialog',{attrs:{"max-width":"100%","dense":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add_box")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsEmployees,"item-text":"name","item-value":"id","label":"Profesional","clearable":"","disabled":_vm.disable,"return-object":""},model:{value:(_vm.editedItem.taker),callback:function ($$v) {_vm.$set(_vm.editedItem, "taker", $$v)},expression:"editedItem.taker"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"Descripción","rules":_vm.rules.in_value,"disabled":_vm.disable,"color":"purple darken-2"},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Total","disabled":_vm.disable,"color":"purple darken-2"},model:{value:(_vm.editedItem.total),callback:function ($$v) {_vm.$set(_vm.editedItem, "total", $$v)},expression:"editedItem.total"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.disable),expression:"disable"}],attrs:{"color":"primary"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("Cancelar Multa ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disable),expression:"!disable"}],attrs:{"color":"pink accent-3","disabled":!_vm.formIsValid,"text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)]},proxy:true},{key:"item.exp_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exp_date.split("T")[0])+" ")]}},{key:"item.hour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exp_date.split("T")[1].split("-")[0].split(".")[0])+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.cancelled)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])],1):_vm._e()]}},{key:"item.cancelled",fn:function(ref){
var item = ref.item;
return [(item.cancelled)?_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","text":""}},[_c('v-icon',{attrs:{"dark":"","center":""}},[_vm._v(" mdi-checkbox-marked-circle ")])],1)],1):_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","text":""}},[_c('v-icon',{attrs:{"dark":"","center":""}},[_vm._v(" mdi-cancel ")])],1)],1)]}},{key:"item.cancel_date",fn:function(ref){
var item = ref.item;
return [(item.cancel_date !== null)?_c('div',[_vm._v(" "+_vm._s(item.cancel_date.split("T")[0])+" "+_vm._s(item.cancel_date.split("T")[1].split("-")[0].split(".")[0])+" ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.iniciar}},[_vm._v("Reiniciar")])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }