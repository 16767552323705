<template>
	<v-app>
		<v-row>
			<v-col>
				<v-data-table
				dense
				:headers="headers"
				:items="items"
				:search="search"
        :single-expand="true"
        :expanded.sync="expanded"
				class="elevation-1"
				:loading="loading"
        show-expand
				:items-per-page="pages"
				loading-text="Cargando..."
				>
         <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Préstamos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
              </v-btn>
              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-autocomplete
                          v-model="editedItem.taker" 
                          :items="itemsEmployees"
                          item-text="name"
                          item-value="id"
                          label="Profesional"
                          clearable
                          return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                          v-model="editedItem.total" 
                          label="Total"
                          :rules="rules.in_req"
                          color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>3
              </v-dialog>  
              <v-dialog v-model="dialogPayment" max-width="100%" dense persistent>
                <v-card>
                  <v-card-title>
                  <span class="headline">Añadir Abono</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-autocomplete
                          v-model="editedItem.taker" 
                          :items="itemsEmployees"
                          item-text="name"
                          item-value="id"
                          label="Profesional"
                          clearable
                          :disabled="disable"
                          return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                          v-model="editedItem.total" 
                          label="Abono"
                          :rules="rules.in_req"
                          color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="savePayment">Guardar</v-btn>
                  </v-card-actions>
                </v-card>3
              </v-dialog>            
            </v-toolbar>
          </template>
          <template v-slot:item.exp_date="{ item }">
            {{ item.exp_date.split("T")[0] }}
          </template>
          <template v-slot:item.hour="{ item }">
            {{ item.exp_date.split("T")[1].split("-")[0].split(".")[0] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="!item.cancelled">
              <v-icon small class="mr-2" @click="editPayment(item)">mdi-pencil</v-icon>
            </div>
          </template>
          <template v-slot:item.cancelled="{ item }">
            <div v-if="item.cancelled">
              <v-btn
                class="ma-2"
                color="success"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                class="ma-2"
                color="red"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-cancel
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                dense
                :headers="itemsHeaders"
                :items="item.payment_loans"
                hide-default-footer
                class="elevation-1"

              >
              <template v-slot:item.cancel_payment_date="{ item }">
                {{ item.cancel_payment_date.split("T")[0] }}
              </template>
              <template v-slot:item.hour="{ item }">
                {{ item.cancel_payment_date.split("T")[1].split("-")[0].split(".")[0] }}
              </template>
            </v-data-table>
            </td>
          </template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
	</v-app>
</template>

<script>

import { ApiPay } from "../billing/ApiPay";
import { ApiExp } from "./ApiExp"

export default {
	name: "Loans",
	data() {
    return{
      api: new ApiExp(),
      apiPay: new ApiPay(),
      items:[],
      itemsEmployees: [],
      expanded: [],
      loading: false,
      dialog:false,
      dialogPayment:false,
      search:"",
      pages:-1,
      editedIdex:-1,
      disable:false,
      editedItem:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      defaultItem:{
          id:-1,
          taker:{id:-1},
          total:0,
      },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id_by_enterprise",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "exp_date",
        },
        {
          text: "Hora",
          align: "center",
          sortable: true,
          value: "hour",
        },
        {
          text: "Profesional",
          align: "center",
          sortable: true,
          value: "taker_desc",
        },
        {text:'Total',value:'total' ,sortable:true},
        {text:'Debe',value:'debe' ,sortable:true},
        {text:'Acciones',value:'actions',sortable:false},
      ],
      itemsHeaders: [
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "cancel_payment_date",
        },
        {
          text: "Hora",
          align: "center",
          sortable: true,
          value: "hour",
        },
        {text:'Total',value:'total' ,sortable:true},
      ],
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
    }
	},
  computed:{
    formTitle(){
      return (this.editedIdex === -1 ? "Nuevo": "Editar") + " Préstamo"
    },
    formIsValid () {
      return (
        this.editedItem.taker &&
        this.editedItem.total >= 1
      )
    },      
  },
   watch:{
      dialog(val){
          val || this.close()
      },
      dialogPayment(val){
          val || this.close()
      },
  },
  methods:{
    async iniciar(){
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}` 
        let r = await this.api.getLoansP(params)
        let rE = await this.apiPay.getEmpleados();
        this.items = r

        this.itemsEmployees = rE;
      } catch (error) {
          alert(error);
          // this.mensaje(error,"Error","error")
          this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    close(){
      this.dialog = false
      this.dialogPayment = false
      this.disable = false
      this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async editPayment(item){
      this.editedIdex = this.items.indexOf(item)
      this.editedItem = Object.assign({},item)
      this.editedItem["total"] = 0
      this.dialogPayment = true
      this.disable = true
    },
    async save(){
      const cp = this.editedItem;
      cp.enterprise = localStorage.getItem("SucursalId")
      cp.taker = this.editedItem["taker"]["id"]
      
      try {
        this.loading = true
        let r = await this.api.saveLoans(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }else{
          this.$swal("Guardado","Préstamo Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
    async savePayment(){

      const cp = {};
      cp["id"] = -1      
      cp["loan"] = this.editedItem["id"]      
      cp["total"] = this.editedItem["total"]
      console.log(cp)

      try {
        this.loading = true
        let r = await this.api.saveAbonos(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }else{
          this.$swal("Guardado","Abono Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
  },
  created(){
    this.iniciar()
  }
};
</script>